<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div class="uk-width-medium">
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'address')" type="text" placeholder="Search address">
                    </div>
                </div>
                <div class="uk-width uk-margin-remove-top">
                    <div class="uk-text-right">
                        <button
                            class="uk-button uk-button-primary"
                            style="background:#4287f5"
                            @click="select"
                        >Pilih semua
                        </button>&#160;
                        <button
                            v-show="this.selected.length > 0"
                            class="uk-button uk-button-primary"
                            style="background:#e63c3c"
                            @click="block(selected, 'false')"
                        >Blokir Semua
                        </button>&#160;
                        <button
                            v-show="this.selected.length > 0"
                            class="uk-button uk-button-primary"
                            style="background:#333747"
                            @click="showSendModal"
                        >Kirim Notifikasi
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nama Perusahaan</th>
                            <th>Lokasi Perusahaan</th>
                            <th>Email</th>
                            <th>No. Telpon</th>
                            <th>Alamat</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.docs.length>0">
                        <tr v-for="(user, i) in users.docs" :key="i">
                            <td>
                                <label class="form-checkbox">
                                    <input type="checkbox" :value="user._id" v-model="selected">
                                    <i class="form-icon"></i>
                                </label>
                            </td>
                            <td>{{user.company ? user.company.name : '-'}}</td>
                            <td>{{user.company ? user.company.address : '-'}}</td>
                            <td>{{user.email || '-'}}</td>
                            <td>{{user.phone_number || '-'}}</td>
                            <td>{{user.address || '-'}}</td>
                            <td><div class="label" :style="`background-color: ${user.is_active ? '#00cc00' : '#e63c3c'}`">{{user.is_active ? 'Active' : 'Block'}}</div></td>
                            <td>
                               <button
                                    style="width:100px; cursor:pointer; margin-bottom:5px; height:30px; background:#333747"
                                    class="uk-button-primary"
                                    v-clipboard:copy="user._id"
                                    v-clipboard:success="onCopy"
                                >Copy Id</button><br/>
                                <button
                                    style="width:100px; cursor:pointer; margin-bottom:5px; height:30px; background:#ffa51f"
                                    class="uk-button-primary"
                                    @click="editUser"
                                >Ubah</button><br/>
                                <button
                                    style="width:100px; cursor:pointer; margin-bottom:5px; height:30px;"
                                    class="uk-button-primary"
                                    @click="changeStatus(user._id, 'true')"
                                >Activate</button><br/>
                                <button
                                    style="width:100px; cursor:pointer; height:30px; background:#e63c3c"
                                    class="uk-button-primary"
                                    @click="changeStatus(user._id, 'false')"
                                >Blokir</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                :total-data="users.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="send-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideSendModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Send Notification</h2>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-form-label">Message</label>
                    <div class="uk-width-1-1 uk-inline">
                        <textarea
                            class="uk-input"
                            type="text"
                            placeholder="input message"
                            v-model="notification">
                        </textarea>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default"
                    type="button" @click="sendNotif">Send</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import api from '@/utils/api';
import { API_USER } from '@/utils/api-url';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1
            },
            notification: '',
            selected: [],
            selectAll: false
        };
    },
    components: {
        Pagination,
        EmptyTable
    },
    computed : {
        ...mapGetters({
            users: 'user/employers',
        })
    },
    watch: {
        meta() {
            this.setUserMeta();
        },
    },
    mounted() {
        this.setUserMeta();
    },
    methods: {
        ...mapActions({
            getUser: 'user/getEmployer'
        }),
        fullName(data) {
            return formatter.getFullname(data);
        },
        setUserMeta() {
            this.getUser(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        select() {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.users.docs) {
                    this.selected.push(this.users.docs[i]._id);
                }
            }
            this.selectAll = true;
        },
        async changeStatus(id, status) {
            try {
                await api.apiPatchAuth(API_USER.CHANGE_EMPLOYER_STATUS(id, status));
                notificationSuccess('Status changed !');
                this.getUser(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        editUser() {
            this.$router.push(`/admin/cv-classification/create`);
        },
        async block(ids, status) {
            try {
                await api.apiPatchAuth(API_USER.BLOCK_SELECT_CANDIDATE(ids, status));
                notificationSuccess('Block success');
                this.getUser(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showSendModal() {
            if (this.selected.length > 0) {
                await window.UIkit.modal('#send-modal').show();
            }
        },
        async hideSendModal() {
            await window.UIkit.modal('#send-modal').hide();
        },
        async sendNotif() {
            try {
                await api.apiPatchAuth(API_USER.SEND_SELECT_NOTIF_EMPLOYER(this.selected, this.notification));
                notificationSuccess('Notif send');
                this.getUser(this.meta);
                await window.UIkit.modal('#send-modal').hide();
            } catch (err) {
                notificationDanger(err);
            }
        }
    },
};
</script>
